<template>
  <section v-if="componentContent.products" class="mb-40 lg:mb-50" data-aller-id="productList">
    <WidthWrapper>
      <LazySnapSlider
        :slider-conf="{
          items: 1,
          stagePadding: '.2',
          slideClasses: 'max-w-[80vw] lg:max-w-[320px]',
          responsive: [
            {
              breakpoint: 600,
              items: 2,
              stagePadding: '.2',
            },
            {
              breakpoint: 768,
              items: 3,
              stagePadding: '.2',
            },
            {
              breakpoint: 1024,
              items: 4,
              stagePadding: 0
            },
          ],
        }"
        :items="componentContent.products"
        class="-mr-16 lg:mr-0"
      >
        <template #sliderHeader>
          <SecondaryTitle
            :title="componentContent.title"
            class="pl-5"
          />
        </template>
        <template v-for="(product, index) in componentContent.products" #[index] :key="index">
          <article class="h-full">
            <NuxtLink
              :to="product.url"
              class="group relative block overflow-hidden rounded-lg text-white"
              :aria-label="product.title"
              @click="trackClick('click_promotion_element', product)"
            >
              <div class="aspect-[243/212] overflow-hidden">
                <img v-if="product.media?.imageUrl" :alt="product.title" :src="`${product.media?.imageUrl}?width=243&height=212&format=webp`" :srcset="`${product.media?.imageUrl}?width=243&height=212&format=webp 243w, ${product.media?.imageUrl}?width=486&height=424&format=webp 486w, ${product.media?.imageUrl}?width=729&height=636&format=webp 729w`" sizes="(min-width:1024px) 285px, (min-width:768px) 30vw, 80vw" width="243" height="212" loading="lazy" class="scale-[1.02] transition-transform duration-500 will-change-transform group-hover:scale-110">
              </div>
              <div class="relative flex flex-col items-center p-20">
                <img v-if="product.media?.imageUrl" :alt="product.title" :src="`${product.media?.imageUrl}?width=243&height=212&format=webp`" :srcset="`${product.media?.imageUrl}?width=243&height=212&format=webp 243w, ${product.media?.imageUrl}?width=486&height=424&format=webp 486w, ${product.media?.imageUrl}?width=729&height=636&format=webp 729w`" sizes="(min-width:1024px) 285px, (min-width:768px) 30vw, 80vw" width="243" height="212" loading="lazy" class="absolute inset-0 -z-10 h-full w-full">
                <div class="absolute inset-0 -z-10 bg-black/30 backdrop-blur-3xl" />
                <div class="line-clamp-2 text-center text-white">
                  {{ product.title }}
                  <br>
                  {{ product.priceText }}
                </div>
              </div>
            </NuxtLink>
          </article>
        </template>
      </LazySnapSlider>
    </WidthWrapper>
  </section>
</template>
<script setup lang="ts">
import { type ProductListBlock } from '@aller/nuxt-allerservice-sdk/transformers';
const props = defineProps({
  componentContent: {
    type: Object as PropType<ProductListBlock>,
    required: true,
  },
});

const trackClick = (event: string, item) => {
  useGTM().pushElement(event, {
    section_id: 'productlist',
    section_text: props.componentContent.title,
    element_id: item.guid,
    element_text: item.title,
    element_link: item.url,
  });
};

onMounted(() => {
  props.componentContent.products.forEach((product) => {
    trackClick('view_promotion_element', product);
  });
});
</script>
